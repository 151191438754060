/* You can add global styles to this file, and also import other style files */

:root {
  --app-fallback-color-primary: #444444;
  --app-fallback-color-primary-darken: darken(#444444, 20);
  --app-fallback-color-primary-lighten: lighten(#444444, 20);

  --app-fallback-color-secondary: inherit;
  --app-fallback-color-bg-primary: var(--app-fallback-color-primary);
  --app-fallback-color-bg-secondary: var(--app-fallback-color-secondary);
  --app-fallback-color-text-primary: rgba(0,0,0,.85);
 
  // --app-color-primary-langedrag: #9c3359;

  --app-color-primary     : var(--app-custom-color-primary,       var(--app-theme-color-primary,      --app-fallback-color-primary      ));
  --app-color-primary-darken     : var(--app-custom-color-primary-darken,       var(--app-theme-color-primary-darken,      --app-fallback-color-primary-darken      ));
  --app-color-primary-lighten     : var(--app-custom-color-primary-lighten,       var(--app-theme-color-primary-lighten,      --app-fallback-color-primary-lighten      ));
  
  --app-color-secondary   : var(--app-custom-color-secondary,     var(--app-theme-color-secondary,    --app-fallback-color-secondary    ));
  --app-color-bg-primary  : var(--app-custom-color-bg-primary,    var(--app-theme-color-bg-primary,   --app-fallback-color-bg-primary   ));
  --app-color-bg-secondary: var(--app-custom-color-bg-secondary,  var(--app-theme-color-bg-secondary, --app-fallback-color-bg-secondary ));
  --app-color-text-primary: var(--app-custom-color-text-primary,  var(--app-theme-color-text-primary, --app-fallback-color-text-primary ));   


 // --lib-color-test: color.adjust($color-blue, $alpha: -0.4); 
}



@import '../../../../node_modules/quill/dist/quill.core.css';
@import '../../../../node_modules/quill/dist/quill.bubble.css';
@import '../../../../node_modules/quill/dist/quill.snow.css';


@import "../../../../node_modules/ng-zorro-antd/ng-zorro-antd.variable.min.css";


@import '../../../../node_modules/@mobiscroll/angular-ivy/dist/css/mobiscroll.scss';



@import "../../../btlib-core/src/styles/styles-tailwind-light.scss";

@import "../../../btlib-core/src/styles/styles-lib.scss";
@import '../../../btlib-core/src/styles/styles-widget.scss';

@import "../../../btlib-core/src/styles/styles-custom.scss";


quill-editor {
  width: 100%;
  .ql-editor {
    p {
      margin-bottom: 1em;
    }
  }
}




body{
  height: auto;
}

.container-1600 {
  // padding: 24px;
  max-width: 1600px;
  // margin: 0 auto;
}



