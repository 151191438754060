
// 


/* custom-style */
.has-custom-style {
  .custom-style {

    .ant-btn-primary {
      border-color: var(--app-color-primary);
      background: var(--app-color-primary);
    }
    .ant-btn-primary:hover, .ant-btn-primary:focus {
      border-color: var(--app-color-primary-lighten);
      background: var(--app-color-primary-lighten);
    }

    
  }
}
