/* You can add global styles to this file, and also import other style files */

:root {
     
  --lib-color-yellow:       rgb(255, 222, 23);
  --lib-color-yellow-orange:rgb(249, 166, 28);
  --lib-color-orange:       rgb(246, 129, 33);
  --lib-color-orange-red:   rgb(238, 62, 62);
  --lib-color-red:          rgb(237, 28, 36);
  --lib-color-red-purple:   rgb(163, 36, 143);
  --lib-color-purple:       rgb(92, 46, 145);
  --lib-color-purple-blue:  rgb(33, 64, 155);
  --lib-color-blue:         rgb(1, 102, 180);
  --lib-color-blue-green:   rgb(1, 170, 173);
  --lib-color-green:        rgb(0, 165, 96);
  --lib-color-green-yellow: rgb(112, 190, 68);
}

.bt-page-wrapper {
  // padding: 0.6em;
}
.bt-content-main {
  min-height: 100px;

}

.bt-max-width-320 {
  max-width: 320px;
  margin: 50px auto 20px auto;
}

.bt-max-width-1200 {
  max-width: 1200px;
  margin: 10px auto 10px auto;
}
.bt-max-width-1600 {
  max-width: 1600px;
  margin: 0px auto;
}


.inner-content {
  background: #fff;
  padding: 24px;
  min-height: 280px;

  max-width: 1600px;
  margin: 0 auto;

  &.bt-max-width-1200 {
    max-width: 1200px;
    margin: 10px auto 10px auto;
  }
}

@media only screen and (max-width: 576px) {
  .bt-form-label-placeholder {
    margin-top: -15px;
  }
}
@media only screen and (min-width: 577px) {
  .bt-form-label-placeholder {
    height: 30px;
  }
}

.buttons > [nz-button] {
  margin-right: 0.75rem;
  margin-top: 0.75rem;

}
.buttons-right {
  text-align: right;
  > [nz-button] {
    margin-left: 0.75rem;
    margin-top: 0.75rem;
  }
}

/* */
.ant-modal-content {

  background: #f0f2f5;
}


nz-modal-container > .ant-modal {
  max-width: 95vw;
}

@media (min-width: 1600px) { 
  nz-modal-container > .ant-modal {
    max-width: 1590px;
  }
}


nz-tag.tag-chip {
  border-radius: 100px;
  padding: 8px 16px;
}

/*  */
.nz-table-xs .ant-table.ant-table-small {
  .ant-table-thead > tr > th, 
  .ant-table-tbody > tr > td {
    padding: 4px;
  }
}

.nz-table-striped tbody tr:nth-child(2n) td {
  background-color: rgba(0,0,0,0.05);
}

.ant-table-thead > tr {
  > th.text-right {
    text-align: right;
  }
  > th.text-center {
    text-align: center;
  }
}


.bt-table {
  border: 1px solid rgba(0,0,0,.06);
  width: 100%;
  border-radius: 2px;

  table {
    table-layout: auto;
    border-collapse: collapse;
    width: 100%;

    tr {
      border-bottom: 1px solid rgba(0,0,0,.06);

      td {
        padding: 4px 6px;
        border-right: 1px solid rgba(0,0,0,.06);

        &.btt-label {
          background-color: #fafafa;
        }
      }
      th {
        padding: 4px 6px;
        border-right: 1px solid rgba(0,0,0,.06);

        text-align: left;

        &.btt-label {
          background-color: #fafafa;
        }
        &.text-right {
          text-align: right;
        }
      }
    
    }

  }

  &.bt-condensed {
    table {

      tr {
    
        td {
          padding: 4px 6px;

        }
        th {
          padding: 4px 6px;
        }
      
      }

    }
  }
}

@media (min-width: 576px) { 
  .bt-table {

    table {

      tr {
    
        td {
          padding: 16px 24px;

        }
        th {
          padding: 16px 24px;
        }
      
      }

    }
  }
}

.bt-row-table {
  border: 1px solid rgba(0,0,0,.06);
  width: 100%;
  border-radius: 2px;


  div[nz-row] {
    border-bottom: 1px solid rgba(0,0,0,.06);

    div[nz-col] {
      // padding: 12px 18px;
      border-right: 1px solid rgba(0,0,0,.06);

      &.btt-label {
        background-color: #fafafa;
      }
    }
    // th {
    //   padding: 16px 24px;
    //   border-right: 1px solid rgba(0,0,0,.06);

    //   text-align: left;

    //   &.btt-label {
    //     background-color: #fafafa;
    //   }
    //   &.text-right {
    //     text-align: right;
    //   }
    // }
  
  }

  
}



.expand-gradient {


  &.not-expanded {
    position:relative;
    height: 50px;
    top: -50px;
    background-image: linear-gradient(to bottom, rgba(255,255,255,0.5), rgba(255,255,255,1));
    

    .expand-button-div {
    
      position:absolute;
      bottom: -30px; // eller bottom: 0; ?
      right: 0;
    }

    &.expand-100 {
      height: 100px;
      top: -80px;
    }
  }

}


table {
  tr.strikeout {
    td { position: relative; }

    td:before {
      content: " ";
      position: absolute;
      top: 50%;
      left: 0;
      border-bottom: 1px solid #111;
      width: 100%;
    }
  
    td:after {
      //content: "\00B7";
      content: "";
      font-size: 1px;
    }
  }

}

.table-responsive-wrapper {
  overflow-x: auto;

  @media screen and (max-width: 575px) {
    width: 100%;
    // margin-bottom: (@line-height-computed * .75);
    overflow-y: hidden;
    // -ms-overflow-style: -ms-autohiding-scrollbar;
    // border: 1px solid @table-border-color;

    // Tighten up spacing
    > .table {
      margin-bottom: 0;

      // Ensure the content doesn't wrap
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th,
          > td {
            white-space: nowrap;
          }
        }
      }
    }

    // Special overrides for the bordered tables
    > .table-bordered {
      border: 0;

      // Nuke the appropriate borders so that the parent can handle them
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th:first-child,
          > td:first-child {
            border-left: 0;
          }
          > th:last-child,
          > td:last-child {
            border-right: 0;
          }
        }
      }

      // Only nuke the last row's bottom-border in `tbody` and `tfoot` since
      // chances are there will be only one `tr` in a `thead` and that would
      // remove the border altogether.
      > tbody,
      > tfoot {
        > tr:last-child {
          > th,
          > td {
            border-bottom: 0;
          }
        }
      }

    }
  }
}


.product-grid {
  .cell {

  
    .cell-img {
      display: block;
      // height: 250px;
      background-color: #fff; /* Used if the image is unavailable */
      background-position: center center; /* Center the image */
      background-repeat: no-repeat; /* Do not repeat the image */
      background-size: cover; /* Resize the background image to cover the entire container */
    
      position: relative;

      border-radius: 15px;
      height: 250px;

      &.h-300px {
        height: 300px;

      }
    }
    .header {
      font-size: 16px;
      margin-top: 5px;
    }
    .lead {
      height: auto;
      overflow-y: auto; 
      line-height: 1.4em;
    }


    ng-icon {
      vertical-align: middle;
    }


    .grid-icons {

      
    }
    .grid-icons-top-right {
      text-align: right;
      padding: 10px 10px 0 0;
      span {
        color: white;
        font-size: 30px;
      }
    }
    .grid-icons-bottom-right {
      position: absolute;

      bottom: 5px;
      right: 5px;
      span {
        color: white;
        font-size: 30px;
      }
    }
    
    .grid-icons-bottom-left {
      position: absolute;

      bottom: 5px;
      left: 5px;
      span {
        color: white;
        font-size: 30px;
      }
    }
  }
}



#app-root nz-descriptions.descriptions-xs  .ant-descriptions-item-label {
  padding: 4px 4px;
}

@media only screen and (max-width: 576px) {
  date-range-popup {
    .ant-picker-panels {
      display: block !important;
      // .ant-picker-panel {
      //   width: 90vv;
      //   float: left;

      // }
    }
  }


  // .ant-calendar-range {
  //   width: 276px;
  //   .ant-calendar-range-part {
  //     width: 100%;
  //   }
  //   .ant-calendar-range-right {
  //     float: left;
  //     border-top: 1px solid #e8e8e8;
  //   }
  // }
}

.ql-container {

  background: #fff;

}


hr {
  border: 1px #ddd solid;
}


/* order status */
.status-reservation 	{ background-color: yellow !important; color: black;					}
.status-waiting-list 	{ background-color: lightgreen !important; color: black;				}
.status-definitive 		{ background-color: green !important; 	color: white; 	}
.status-cancelled 		{ background-color: red !important; 	color: white; 	}
.status-long-term 		{ background-color: blue !important; 	color: white; 	}
.status-provider 		  { background-color: purple !important; 	color: white; 	}
.status-availability 	{ background-color: #dddddd !important; color: black;				}
.status-offer 	      { background-color: #6fa8dc !important; color: black;				}

.status-bg-reservation   { background-color: #f9f998 !important;  }
.status-bg-waiting-list  { background-color: #a5ff7f !important; }
.status-bg-definitive    { background-color: #DFF0D8 !important;   }
.status-bg-cancelled     { background-color: #efd7d7 !important;    }
.status-bg-long-term     { background-color: #a8abff !important;  }
.status-bg-provider      { background-color: #e4b7ff !important; }
.status-bg-availability  { background-color: #dddddd !important;   }
.status-bg-offer         { background-color: #6fa8dc !important;   }



/* Colors */
// 'yellow', 'yellow-orange', 'orange', 'orange-red', 'red', 'red-purple', 'purple', 'purple-blue', 'blue', 'blue-green', 'green', 'green-yellow'

$color-yellow: #FFDE17;
$color-yellow-orange: #F9A61C;
$color-orange: #F68121;
$color-orange-red: #EE3E3E;
$color-red: #ED1C24;
$color-red-purple: #A3248F;
$color-purple: #5C2E91;
$color-purple-blue: #21409B;
$color-blue: #0166B4;
$color-blue-green: #01AAAD;
$color-green: #00A560;
$color-green-yellow: #70BE44;


/* Styles */
.bts-none 			{ } 
.bts-green 			{ background-color: $color-green !important;  color: white !important; } 
.bts-yellow 			{ background-color: $color-yellow !important; } 
.bts-orange 			{ background-color: $color-orange !important; } 
.bts-red 			{ background-color: $color-red !important; color: white !important; } 


.bgc-y, 	.bgc-yellow 		    { background-color: $color-yellow         } 	/* Gul */
.bgc-yo, 	.bgc-yellow-orange 	{ background-color: $color-yellow-orange  } 	/* Gul-Orange */
.bgc-o, 	.bgc-orange 		    { background-color: $color-yellow-orange  } 	/* Orange */
.bgc-or, 	.bgc-orange-red 	  { background-color: $color-orange-red     } 	/* Orange-Rød */
.bgc-r, 	.bgc-red 			      { background-color: $color-red            } 	/* Rød */
.bgc-rp, 	.bgc-red-purple 	  { background-color: $color-red-purple     } 	/* Rød-Lilla */
.bgc-p, 	.bgc-purple 		    { background-color: $color-purple         } 	/* Lilla */
.bgc-pb, 	.bgc-purple-blue 	  { background-color: $color-purple-blue    } 	/* Lilla-Blå */
.bgc-b, 	.bgc-blue 			    { background-color: $color-blue           } 	/* Blå */
.bgc-bg, 	.bgc-blue-green 	  { background-color: $color-blue-green     } 	/* Blå-Grønn */
.bgc-g, 	.bgc-green 			    { background-color: $color-green          } 	/* Grønn */
.bgc-gy, 	.bgc-green-yellow 	{ background-color: $color-green-yellow   } 	/* Grønn-Gul */


.bgc-gray-10 { background-color: hsl(0, 0%, 10%); }
.bgc-gray-20 { background-color: hsl(0, 0%, 20%); }
.bgc-gray-30 { background-color: hsl(0, 0%, 30%); }
.bgc-gray-40 { background-color: hsl(0, 0%, 40%); }
.bgc-gray-50 { background-color: hsl(0, 0%, 50%); }
.bgc-gray-60 { background-color: hsl(0, 0%, 60%); }
.bgc-gray-70 { background-color: hsl(0, 0%, 70%); }
.bgc-gray-80 { background-color: hsl(0, 0%, 80%); }
.bgc-gray-90 { background-color: hsl(0, 0%, 90%); }
.bgc-gray-95 { background-color: hsl(0, 0%, 95%); }

.bgc-white { background-color: white; }
.bgc-gray { background-color: gray; } 
.bgc-black { background-color: black; }

/* fg */
.fgc-y, 	.fgc-yellow 		{ color: #FFDE17; } 	/* Gul */
.fgc-yo, 	.fgc-yellow-orange 	{ color: $color-yellow-orange; } 	/* Gul-Orange */
.fgc-o, 	.fgc-orange 		{ color: $color-orange; } 	/* Orange */
.fgc-or, 	.fgc-orange-red 	{ color: $color-orange-red; } 	/* Orange-Rød */
.fgc-r, 	.fgc-red 			{ color: $color-red; } 	/* Rød */
.fgc-rp, 	.fgc-red-purple 	{ color: $color-red-purple; } 	/* Rød-Lilla */
.fgc-p, 	.fgc-purple 		{ color: $color-purple; } 	/* Lilla */
.fgc-pb, 	.fgc-purple-blue 	{ color: $color-purple-blue; } 	/* Lilla-Blå */
.fgc-b, 	.fgc-blue 			{ color: $color-blue; } 	/* Blå */
.fgc-bg, 	.fgc-blue-green 	{ color: $color-blue-green; } 	/* Blå-Grønn */
.fgc-g, 	.fgc-green 			{ color: $color-green; } 	/* Grønn */
.fgc-gy, 	.fgc-green-yellow 	{ color: $color-green-yellow; } 	/* Grønn-Gul */

a {
  &.fgc-y, 	&.fgc-yellow 		{ color: $color-yellow } 	/* Gul */
  &.fgc-yo, 	&.fgc-yellow-orange 	{ color: $color-yellow-orange; } 	/* Gul-Orange */
  &.fgc-o, 	&.fgc-orange 		{ color: $color-orange; } 	/* Orange */
  &.fgc-or, 	&.fgc-orange-red 	{ color: $color-orange-red; } 	/* Orange-Rød */
  &.fgc-r, 	&.fgc-red 			{ color: $color-red; } 	/* Rød */
  &.fgc-rp, 	&.fgc-red-purple 	{ color: $color-red-purple; } 	/* Rød-Lilla */
  &.fgc-p, 	&.fgc-purple 		{ color: $color-purple; } 	/* Lilla */
  &.fgc-pb, 	&.fgc-purple-blue 	{ color: $color-purple-blue; } 	/* Lilla-Blå */
  &.fgc-b, 	&.fgc-blue 			{ color: $color-blue; } 	/* Blå */
  &.fgc-bg, 	&.fgc-blue-green 	{ color: $color-blue-green; } 	/* Blå-Grønn */
  &.fgc-g, 	&.fgc-green 			{ color: $color-green; } 	/* Grønn */
  &.fgc-gy, 	&.fgc-green-yellow 	{ color: $color-green-yellow; } 	/* Grønn-Gul */
  
}

.fgc-gray-10 { color: hsl(0, 0%, 10%); }
.fgc-gray-20 { color: hsl(0, 0%, 20%); }
.fgc-gray-30 { color: hsl(0, 0%, 30%); }
.fgc-gray-40 { color: hsl(0, 0%, 40%); }
.fgc-gray-50 { color: hsl(0, 0%, 50%); }
.fgc-gray-60 { color: hsl(0, 0%, 60%); }
.fgc-gray-70 { color: hsl(0, 0%, 70%); }
.fgc-gray-80 { color: hsl(0, 0%, 80%); }
.fgc-gray-90 { color: hsl(0, 0%, 90%); }
.fgc-gray-95 { color: hsl(0, 0%, 95%); }

.fgc-white { color: white; }
.fgc-gray { color: gray; } 
.fgc-black { color: black; }


.btn-yellow[nz-button] {
  background-color:  $color-yellow;
  border-color:  $color-yellow;
  color: black;
  &:hover,  &:focus {
    background-color: darken( $color-yellow, 10%);
  }
}
.btn-yellow-orange[nz-button] {
  background-color:  $color-yellow-orange;
  border-color:  $color-yellow-orange;
  color: black;
  &:hover,  &:focus {
    background-color: darken( $color-yellow-orange, 10%);
  }
}
.btn-orange[nz-button] {
  background-color:  $color-orange;
  border-color:  $color-orange;
  color: white;
  &:hover,  &:focus {
    background-color: darken( $color-orange, 10%);
  }
}
.btn-orange-red[nz-button] {
  background-color:  $color-orange-red;
  border-color:  $color-orange-red;
  color: white;
  &:hover,  &:focus {
    background-color: darken( $color-orange-red, 10%);
  }
}
.btn-red[nz-button] {
  background-color:  $color-red;
  border-color:  $color-red;
  color: white;
  &:hover,  &:focus {
    background-color: darken( $color-red, 10%);
  }
}
.btn-red-purple[nz-button] {
  background-color:  $color-red-purple;
  border-color:  $color-red-purple;
  color: white;
  &:hover,  &:focus {
    background-color: darken( $color-red-purple, 10%);
  }
}
.btn-purple[nz-button] {
  background-color:  $color-purple;
  border-color:  $color-purple;
  color: white;
  &:hover,  &:focus {
    background-color: darken( $color-purple, 10%);
  }
}
.btn-purple-blue[nz-button] {
  background-color:  $color-purple-blue;
  border-color:  $color-purple-blue;
  color: white;
  &:hover,  &:focus {
    background-color: darken( $color-purple-blue, 10%);
  }
}
.btn-blue[nz-button] {
  background-color:  $color-blue;
  border-color:  $color-blue;
  color: white;
  &:hover,  &:focus {
    background-color: darken( $color-blue, 10%);
  }
}
.btn-blue-green[nz-button] {
  background-color:  $color-blue-green;
  border-color:  $color-blue-green;
  color: white;
  &:hover,  &:focus {
    background-color: darken( $color-blue-green, 10%);
  }
}
.btn-green[nz-button] {
  background-color:  $color-green;
  border-color:  $color-green;
  color: white;
  &:hover,  &:focus {
    background-color: darken( $color-green, 10%);
  }
}
.btn-green-yellow[nz-button] {
  background-color:  $color-green-yellow;
  border-color:  $color-green-yellow;
  color: white;
  &:hover,  &:focus {
    background-color: darken( $color-green-yellow, 10%);
  }
}





/* custom scrollbar */
.scrollbar-pretty {
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

}


@media (max-width: 575px) { // xs

} 
@media (min-width: 576px) { // sm

}
@media (min-width: 768px) { // md

}
@media (min-width: 992px) { // lg

}
@media (min-width: 1200px) { // xl

} 
@media (min-width: 1600px) { // xxl

} 
