/* You can add global styles to this file, and also import other style files */


.text-center { text-align: center; }
.text-right {  text-align: right; }
.text-left {  text-align: left; }

.ant-btn.text-center { text-align: center; }
.ant-btn.text-right {  text-align: right; }
.ant-btn.text-left {  text-align: left; }



.pull-left { float: left; }
.pull-right { float: right; }
.pull-clear { clear: both; }



.w-100 {  width: 100%; }
.w-50 {  width: 50%; }
.w-50i {  width: 50% !important; }
.w-auto {  width: auto; }
.w-100vh {  width: 100vh; }

.text-xs {  font-size: 0.6rem; }
.text-sm {  font-size: 0.8rem; }
.text-md {  font-size: 1.0rem; }
.text-lg {  font-size: 1.5rem; }
.text-xl {  font-size: 2.0rem; }
.text-xxl {  font-size: 4.0rem; }

.text-bold, a.text-bold {  font-weight: bold; }
.text-italic {  font-style: italic; }
.text-nowrap { white-space: nowrap; }
.text-muted {   color: #777; }

.valign-m { vertical-align: middle; }

.text-underline { text-decoration: underline; }
.text-line-through { text-decoration: line-through; }


.cursor-pointer { cursor: pointer;  }
div .cursor-not-allowed { cursor: not-allowed;  }



.text-ellipsis { 
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}


.p-d { padding: 0.75rem;  }  // default 
.p-1 { padding: 0.25rem; }
.p-2 { padding: 0.5rem; }
.p-3 { padding: 0.75rem; }
.p-4 { padding: 1rem; }
.p-8 { padding: 2rem; }
.p-12 { padding: 3rem; }
.p-16 { padding: 4rem; }

.py-d	{ padding-top: 0.75rem; padding-bottom: 0.75rem; }
.py-1	{ padding-top: 0.25rem; padding-bottom: 0.25rem; }
.py-8	{ padding-top: 2rem; padding-bottom: 2rem; }
.py-12	{ padding-top:  3rem; padding-bottom: 3rem; }

.px-d	{ padding-left: 0.75rem; padding-right: 0.75rem; }
.px-1	{ padding-left: 0.25rem; padding-right: 0.25rem; }
.px-4	{ padding-left: 1rem; padding-right: 1rem; }
.px-8	{ padding-left: 2rem; padding-right: 2rem; }

.pl-d { padding-left: 0.75rem; }
.pl-4 { padding-left: 1rem; }
.pl-8 { padding-left: 2rem; }
.pl-12 { padding-left: 3rem; }
.pl-16 { padding-left: 4rem; }
.pl-20 { padding-left: 5rem; }

.pr-d   { padding-right: 0.75rem; }
.pr-8   { padding-right: 2rem; }
.pr-12  { padding-right: 3rem; }

.pt-d   { padding-top: 0.75rem; }
.pt-8   { padding-top: 2rem; }
.pt-12  { padding-top: 3rem; }

.pb-d   { padding-bottom: 0.75rem; }
.pb-8   { padding-bottom: 2rem; }
.pb-12  { padding-bottom: 3rem; }


.m-d { margin: 0.75rem;  }  // default 
.m-0 { margin: 0; }
.m-0-i { margin: 0 !important; }
.m-1 { margin: 0.25rem; }
.m-2 { margin: 0.5rem; }
.m-3 { margin: 0.75rem; }
.m-4 { margin: 1rem; }
.m-8 { margin: 2rem; }
.m-12 { margin: 3rem; }

.my-d	{ margin-top: 0.75rem; margin-bottom: 0.75rem; }
.my-1	{ margin-top: 0.25rem; margin-bottom: 0.25rem; }
.my-4	{ margin-top: 1rem; margin-bottom: 1rem; }
.my-8		{ margin-top: 2rem; margin-bottom: 2rem; }
.my-12		{ margin-top: 3rem; margin-bottom: 3rem; }

.mx-d	{ margin-left: 0.75rem; margin-right: 0.75rem; }
.mx-1	{ margin-left: 0.25rem; margin-right: 0.25rem; }

.mt-d {  margin-top: 0.75rem; }
.mt-0 {  margin-top: 0; }
.mt-1 {  margin-top: 0.25rem; }
.mt-5 {  margin-top: 1.25rem; }
.mt-8 {  margin-top: 2em; }
.mt-12 {  margin-top: 3em; }
.mt-16 {  margin-top: 4em; }

.mb-d {  margin-bottom: 0.75rem; }
.mb-0 {  margin-bottom: 0; }
.mb-1 {  margin-bottom: 0.25rem; }
.mb-5 {  margin-bottom: 1.25rem; }
.mb-12 { margin-bottom: 3rem; }

.ml-d {  margin-left: 0.75rem; }
.ml-0 {  margin-left: 0; }
.ml-1 {  margin-left: 0.25rem; }
.ml-5 {  margin-left: 1.25rem; }

.mr-d {  margin-right: 0.75rem; }
.mr-0 {  margin-right: 0; }
.mr-1 {  margin-right: 0.25rem; }
.mr-5 {  margin-right: 1.25rem; }
.mr-8 {  margin-right: 2rem; }


.block, .d-block { display: block; }	
.inline-block, .d-inline-block { display: inline-block; }	
.inline, .d-inline { display: inline; }	
.hidden, .d-none { display: none; }


.visible { visibility: visible; }
.invisible { visibility: hidden; }


.no-border { 
  border: 0;
}
.border-radius-d {
  border-radius: 0.75rem;
}
.border-radius-left-d {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}
.border-radius-right-d {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}

.border-radius-tl-d {
  border-top-left-radius: 0.75rem;
}
.border-radius-bl-d {
  border-bottom-left-radius: 0.75rem;
}
.border-radius-tr-d {
  border-top-right-radius: 0.75rem;
}
.border-radius-br-d {
  border-bottom-right-radius: 0.75rem;
}




// 
@media (max-width: 576px) { 
  .xs\:block { display: block; }	
  .xs\:inline-block { display: inline-block; }	
  .xs\:inline { display: inline; }	
  .xs\:hidden { display: none; }

  .xs\:visible { visibility: visible; }
  .xs\:invisible { visibility: hidden; }

  .xs\:mx-d { margin-left: 0.75rem; margin-right: 0.75rem; }

  .xs\:m-0 { margin: 0; }
  
  .xs\:p-12 { padding: 3rem; }
  
  .xs\:text-center { text-align: center; }
  .xs\:text-right { text-align: right; }
}

@media (min-width: 576px) { 
  .sm\:block { display: block; }	
  .sm\:inline-block { display: inline-block; }	
  .sm\:inline { display: inline; }	
  .sm\:hidden { display: none; }

  .sm\:visible { visibility: visible; }
  .sm\:invisible { visibility: hidden; }

  .sm\:mx-d { margin-left: 0.75rem; margin-right: 0.75rem; }

  .sm\:m-0 { margin: 0; }
  
  .sm\:p-12 { padding: 3rem; }
  
  .sm\:text-center { text-align: center; }
  .sm\:text-right { text-align: right; }
}
@media (min-width: 768px) { 
  .md\:block { display: block; }	
  .md\:inline-block { display: inline-block; }	
  .md\:inline { display: inline; }
  .md\:hidden { display: none; }	

  .md\:visible { visibility: visible; }
  .md\:invisible { visibility: hidden; }
  
  .md\:mx-d { margin-left: 0.75rem; margin-right: 0.75rem; }
  .md\:m-0 { margin: 0; }
  .md\:mt-d { margin-top: 0.75rem; }
  .md\:p-12 { padding: 3rem; }

  .md\:text-center { text-align: center; }
  .md\:text-right { text-align: right; }
}
@media (min-width: 992px) { 
  .lg\:block { display: block; }	
  .lg\:inline-block { display: inline-block; }	
  .lg\:inline { display: inline; }	
  .lg\:hidden { display: none; }

  .lg\:visible { visibility: visible; }
  .lg\:invisible { visibility: hidden; }
  
  .lg\:mx-d { margin-left: 0.75rem; margin-right: 0.75rem; }
  .lg\:m-0 { margin: 0; }
  .lg\:p-12 { padding: 3rem; }
}
@media (min-width: 1200px) { 
  .xl\:block { display: block; }	
  .xl\:inline-block { display: inline-block; }	
  .xl\:inline { display: inline; }	
  .xl\:hidden { display: none; }

  .xl\:visible { visibility: visible; }
  .xl\:invisible { visibility: hidden; }
  
  .xl\:mx-d { margin-left: 0.75rem; margin-right: 0.75rem; }
  .xl\:m-0 { margin: 0; }
  .xl\:p-12 { padding: 3rem; }
}
@media (min-width: 1600px) { 
  .xxl\:block { display: block; }	
  .xxl\:inline-block { display: inline-block; }	
  .xxl\:inline { display: inline; }	
  .xxl\:hidden { display: none; }

  .xxl\:visible { visibility: visible; }
  .xxl\:invisible { visibility: hidden; }
  
  .xxl\:mx-d { margin-left: 0.75rem; margin-right: 0.75rem; }
  .xxl\:m-0 { margin: 0; }
  .xxl\:p-12 { padding: 3rem; }
}

@media print { 
  .print\:hidden { display: none !important;  }
  .print\:block { display: block; }

}





.cbapi-iframe {
  .if\:block { display: block; }	
  .if\:inline-block { display: inline-block; }	
  .if\:inline { display: inline; }	
  .if\:hidden { display: none; }

  .if\:visible { visibility: visible; }
  .if\:invisible { visibility: hidden; }

  .if\:mx-d { margin-left: 0.75rem; margin-right: 0.75rem; }

  .if\:mt-d { margin-top: 0.75rem; }

  .if\:m-0 { margin: 0; }
  .if\:m-d { margin: 0.75rem; }
  
  .if\:p-d { padding: 0.75rem;  }
  .if\:p-12 { padding: 3rem; }
}

.cbapi-not-iframe {
  .nif\:block { display: block; }	
  .nif\:inline-block { display: inline-block; }	
  .nif\:inline { display: inline; }	
  .nif\:hidden { display: none; }

  .nif\:visible { visibility: visible; }
  .nif\:invisible { visibility: hidden; }

  .nif\:mx-d { margin-left: 0.75rem; margin-right: 0.75rem; }

  .nif\:mt-d { margin-top: 0.75rem; }

  .nif\:m-0 { margin: 0; }
  .nif\:m-d { margin: 0.75rem; }
  
  .nif\:p-d { padding: 0.75rem;  }
  .nif\:p-12 { padding: 3rem; }
}


